// Libs
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { flatten } from 'lodash';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import BreadCrumb from 'components/breadCrumb';

// Components
import { device } from 'components/device';
import Layout from 'components/layout';
import SEO from 'components/seo';

// Styles
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1170px;
  margin: 4.375rem auto;

  @media ${device.laptop} {
    margin: 0 auto 4.375rem;
    padding: 0 1.25rem;
  }
`;

const FeaturedMarketing = styled.section`
  @media ${device.mobile} {
    margin-top: -3rem;
  }
`;

const FeaturedMarketingContent = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3.125rem;

  @media ${device.mobile} {
    gap: unset;
    flex-direction: column-reverse;
  }
`;

const FeaturedMarketingInformation = styled(ReactMarkdown)`
  width: 100%;
  max-width: 26.25rem;

  blockquote {
    width: 100%;
    background: #fff1ef;
    border-radius: 0.5rem;
    margin: 0 0 1.25rem 0;
    padding: 1.25rem;
    font-size: 1rem;

    font-size: 1rem;
    line-height: 1.625rem;
    color: #231f20;

    @media ${device.laptop} {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  @media ${device.laptop} {
    max-width: unset;
  }

  @media ${device.mobile} {
    margin-top: -3rem;
  }

  h1 {
    font-size: 3.375rem;
    color: #231f20;
    font-weight: bold;
    margin: 0 0 1.25rem;

    @media ${device.laptop} {
      width: 100%;
      max-width: 15rem;
      font-size: 1.5rem;
      line-height: 1.875rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #231f20;

    @media ${device.laptop} {
      font-size: 0.875rem;
      line-height: 1.375rem;
    }
  }

  ul {
    margin: 1.25rem 0 2.5rem;
    list-style: none;

    li {
      margin: 0;
      font-size: 1rem;
      line-height: 1.625rem;
      color: #231f20;
      display: flex;
      align-items: center;

      & + li {
        margin-top: 1.25rem;
      }

      img {
        margin-right: 0.5rem;
      }

      @media ${device.laptop} {
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }
  }

  a {
    display: inline-block;
    width: 100%;
    font-size: 1rem;
    color: #ffffff;
    text-align: center;
    padding: 1.25rem;
    background: #45a7df;
    border-radius: 6.25rem;

    @media ${device.laptop} {
      font-size: 0.875rem;
    }
  }
`;

const FeaturedMarketingImage = styled.div`
  width: 100%;
  max-width: 35.625rem;

  @media ${device.laptop} {
    max-width: unset;
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const ExamScheduling = ({ page }) => {
  const image = getMarkdown(page, 'ckv6vs2fc2tf80d21i7a3bcom', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            image && image.handle
          }` || '-'
        }
      />
      <Container>
        <FeaturedMarketing>
          <BreadCrumb
            markdown={
              getMarkdown(page, 'ckyehsra01ibc0c78xy9h31rt', true) || '-'
            }
          />
          <FeaturedMarketingContent>
            <FeaturedMarketingInformation
              children={
                getMarkdown(page, 'ckv6vs2fc2tf80d21i7a3bcom', true) || '-'
              }
            />
            <FeaturedMarketingImage>
              <img src={image?.url} alt="Agendamento de Exames" />
            </FeaturedMarketingImage>
          </FeaturedMarketingContent>
        </FeaturedMarketing>
      </Container>
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckv6vrgtk2t8x0a70ghisyx3o" }) {
                fragments {
                  id
                  markdown
                  localizations {
                    markdown
                  }
                  assetpicker {
                    handle
                    width
                    height
                    url
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => (
        <ExamScheduling page={response?.gcms?.site?.pages} {...props} />
      )}
    />
  );
};
